import React, { useCallback, useEffect, useState } from "react";
import { usePostHog } from "posthog-js/react";
import useClevertap from "../hooks/useClevertap";
import MuzifyLogo from "../assets/images/MuzifyLogo.svg";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Assetlogo from "../assets/images/logo.02bb3edcb660564e75f7fec89fbdb88b.svg";
import Header from "../components/Header";
import useAsset from "../hooks/useAsset";
import ScoreCard from "../components/ScoreCard";
import { FiEdit } from "react-icons/fi";
import UpdateUsernameModal from "../components/UpdateUsernameModal";
import DeleteAccountModal from "../components/DeleteAccountModal";

const Profile = () => {
  const navigate = useNavigate();
  const posthog = usePostHog();
  const { handleEventPushClick } = useClevertap();
  const { userInfo, userData, accessToken, googleLogout } = useAsset();
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [badges, setBadges] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [updatedUsername, setUpdatedUsername] = useState("");

  const getBadges = useCallback(async () => {
    if (userData?.uid) {
      try {
        const url = `${process.env.REACT_APP_ASSET_API}/api/v1/user/badges/${userData?.uid}`;

        const response = await fetch(url);
        const data = await response.json();
        if (data?.data?.length > 0) {
          setBadges(data?.data);
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setError(true);
        setLoading(false);
      }
    }
  }, [userData]);

  //   call getBadges
  useEffect(() => {
    getBadges();
  }, [getBadges]);

  useEffect(() => {
    if (userData !== null) {
      if (userData?.providerData?.length > 0) {
        if (userData?.providerData[0]?.providerId === "google.com") {
          setUser(userData?.providerData[0]);
        } else if (userData?.providerData[1]?.providerId === "google.com") {
          setUser(userData?.providerData[0]);
        }
      }
    }
  }, [userData]);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleOpenDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteAccount = async () => {
    posthog.capture("delete_account_clicked");
    handleEventPushClick("MUSICNERD_DELETE_ACCOUNT_CLICKED", {
      user_id: userData?.uid,
      user_email: userData?.email ? userData?.email : "inboxdev.b@gmail.com",
    });
    const url = `${process.env.REACT_APP_ASSET_API}/api/v1/user/profile`;
    try {
      const response = await fetch(url, {
        method: "DELETE",
        headers: {
          Authorization: accessToken,
        },
      });

      const data = await response.json();
      // console.log(data);
      googleLogout();
      if (data.status === "success") {
        alert("Account deleted successfully");
        navigate("/");
      } else {
        alert("Account deletion failed");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className=" h-screen">
      {isOpen && (
        <UpdateUsernameModal
          handleClose={handleClose}
          accessToken={accessToken}
          setUpdatedUsername={setUpdatedUsername}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteAccountModal
          handleClose={() => setIsDeleteModalOpen(false)}
          handleDeleteAccount={handleDeleteAccount}
        />
      )}
      <div className="bg-blue flex md:flex-row flex-col w-screen h-screen justify-between items-center ">
        <div className="flex flex-col h-screen items-center justify-between grow gap-4 py-20">
          <div className="flex justify-center items-center w-full">
            <div className="flex md:justify-center absolute left-2 md:left-0 right-0">
              <Link className="flex flex-row gap-2" to="/">
                <img src={MuzifyLogo} alt="muzify" className="w-32" />
              </Link>
            </div>
            <div className="flex justify-end mr-5 md:mr-10 absolute right-0">
              <button
                className="border-[rgba(239,209,101,1)] border-2 inter rounded-full"
                style={{ padding: "12px 32px" }}
                onClick={() => {
                  localStorage.setItem("loggedIn", "false");
                  googleLogout();
                }}
              >
                <p className="cabinet-grotesk font-bold text-base text-[rgba(239,209,101,1)]">
                  logout
                </p>
              </button>
            </div>
          </div>
          <div className="w-full flex flex-col items-center mt-10 gap-4">
            <div className="flex flex-col items-center justify-center">
              {user && (
                <div className="flex flex-col items-center justify-center">
                  <img
                    src={user?.photoURL}
                    alt="user"
                    className="w-24 h-24 rounded-full"
                  />
                  {/* <input className="text-2xl text-center font-extrabold text-[rgba(239,209,101,1)] cabinet-grotesk">
                    {userInfo?.username ? userInfo?.username : user?.displayName} 

                  </input> */}
                  {/* editable username with edit and save button besides, when clicked on edit the username should be editable */}
                  <div className="flex items-center justify-center py-2 text-2xl text-center font-extrabold text-[rgba(239,209,101,1)] cabinet-grotesk">
                    {updatedUsername?.length > 0
                      ? updatedUsername
                      : userInfo?.username
                      ? userInfo?.username
                      : user?.displayName}
                    <button
                      onClick={() => {
                        setIsOpen(true);
                      }}
                      className="ml-2 text-sm"
                    >
                      <FiEdit
                        width={4}
                        height={4}
                        className="text-[rgba(239,209,101,1)]"
                      />
                    </button>
                  </div>
                  {/* <span className="text-sm text-center text-[rgba(182,182,182,1)]  w-full font-normal">
                    {user?.email}
                  </span> */}
                </div>
              )}
              {badges?.length > 0 && (
                <>
                  <p className="text-white mt-8">
                    Claimed fandom badges ({badges?.length})
                  </p>
                  {/* <div className="flex flex-row justify-center items-center mt-8 px-8 gap-4 sm:gap-6"> */}
                  <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 justify-center items-center mt-10 px-8 gap-4 sm:gap-6">
                    {/* <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 sm:gap-6 md:gap-8"> */}
                    {badges?.map((badge, index) => {
                      return (
                        <button
                          onClick={() => {
                            if (window.location.href.includes("musicnerd.io")) {
                              navigate(
                                `/leaderboard/${badge?.artist_id}`,
                                {
                                  state: {
                                    profile: true,
                                    artistName: badge?.artist_name,
                                    artistImage: badge?.artist_image,
                                  },
                                }
                              );
                            } else {
                              navigate(
                                `/quiz/leaderboard/${badge?.artist_id}`,
                                {
                                  state: {
                                    profile: true,
                                    artistName: badge?.artist_name,
                                    artistImage: badge?.artist_image,
                                  },
                                }
                              );
                            }
                          }}
                          key={index}
                        >
                          <ScoreCard
                            key={index}
                            badge={badge}
                            artistName={badge?.artist_name}
                            artistImage={badge?.artist_image}
                            right={badge?.score / 10}
                            time={badge?.time_taken}
                            percentile={badge?.percentile}
                            created={
                              badge?.created
                                ? new Date(badge?.created * 1000)
                                : null
                            }
                          />
                        </button>
                      );
                    })}
                    {/* </div> */}
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="flex flex-col w-full items-center justify-center pb-5">
            {/* <span className="text-sm text-center text-[rgba(182,182,182,1)] md:w-[63%] md:px-0 px-3  w-full font-normal">
              From the House of{" "}
              <a
                href="https://www.asset.money/"
                target="_blank"
                rel="noreferrer"
                className="font-bold text-[rgba(239,209,101,1)]"
              >
                <img src={Assetlogo} alt="asset" className="w-12 inline" />
                Asset.Money
              </a>
            </span> */}
            <div className="flex justify-center items-center whitespace-nowrap gap-2">
              <a
                className="text-xs text-center text-[rgba(255,255,255,0.4)] md:w-[63%] pt-1 underline w-full font-normal"
                href="https://teamasset.notion.site/MusicNerd-FAQs-To-be-published-8f2020a3f84e4c42b35b8eef6dae4320"
                target="_blank"
                rel="noreferrer"
                onClick={() => {
                  posthog.capture("faqs_clicked");
                }}
              >
                FAQs
              </a>
              {/* delete my account button */}
              <button
                className="text-xs text-center text-[rgba(255,255,255,0.4)] md:w-[63%]  pt-1 underline w-full font-normal"
                onClick={handleOpenDeleteModal}
              >
                Delete My Account
              </button>
            </div>
          </div>
        </div>
      </div>
      <a className="text-[#141a1e] text-sm" href="https://muzify.com/quiz">
          Muzify
      </a>
    </div>
  );
};

export default Profile;
