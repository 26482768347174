import React, { useCallback, useEffect, useState } from "react";
import { usePostHog } from "posthog-js/react";
import { hompageArtists } from "../../constants/homepageArtists";
import { Link, Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Assetlogo from "../../assets/images/logo.02bb3edcb660564e75f7fec89fbdb88b.svg";
import Header from "../../components/Header";
import useClevertap from "../../hooks/useClevertap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { replaceDimensionsInUrl } from "../../common_functions/Common";
import { IoIosSearch } from "react-icons/io";
import { FaInstagram, FaTwitter, FaTiktok } from "react-icons/fa";
import { ArrowRightIcon } from "@heroicons/react/20/solid";
import { INCREMENT_ARTIST_GAMEPLAY, INCREMENT_ARTIST_SEARCH, postArtistEvent, searchArtist } from "../../endpoints/api";
import DummyImage from "../../assets/images/dummyImage.png";
import { useDebounce } from "@uidotdev/usehooks";
import ReactGA from "react-ga4";
import { ReactComponent as MuzifyLogo } from "../../assets/images/MuzifyLogo.svg"
import { ReactComponent as MusicNerd } from "../../assets/images/MusicNerdNewLogo.svg"
import clevertap from "clevertap-web-sdk";
import { CLEVERTAP_ACCOUNT_ID } from "../../constants/dataConstant";
import Banner from "../../components/Banner";

let usedIndices = new Set();

const topArtistsonMN = [
  {
    artist_id: "159260351",
    artist_name: "Taylor Swift",
    artist_image:
      "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages112/v4/1d/39/4e/1d394ed5-279c-3186-4b30-e139a3f93adc/8e1d4c20-f1b8-48de-becd-8be82c903060_ami-identity-994af5c375f4c3aa96cd6ced4a700799-2024-03-28T12-56-11.536Z_cropped.png/{w}x{h}bb.jpg",
    artist_about:
      "From country sweetheart to pop supernova, Swift is unstoppable.",
    artist_genres: ["Pop"],
    artist_url: "https://music.apple.com/us/artist/taylor-swift/159260351",
  },
  {
    artist_id: "358714030",
    artist_name: "Imagine Dragons",
    artist_image:
      "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages221/v4/f2/7b/29/f27b29c0-49e6-4b40-e079-9a2163030444/0cd8f23b-d974-42e4-8fe7-1aaa533baf13_ami-identity-0dee3e31f20984eeb377fa8009444095-2024-04-03T19-26-12.960Z_cropped.png/{w}x{h}bb.jpg",
    artist_about: "",
    artist_genres: ["Pop"],
    artist_url: "https://music.apple.com/us/artist/seventeen/999644772",
  },
  {
    artist_id: "883131348",
    artist_name: "BTS",
    artist_image:
      "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/31/77/b0/3177b0da-f5ec-f46c-473a-bdbf4f2161dc/bc5fdca7-12e1-4ea2-a3e9-f9481d5d25bb_ami-identity-5644914dae79e9896405d9b5b7ac9e89-2023-06-09T04-02-49.194Z_cropped.png/{w}x{h}bb.jpg",
    artist_about:
      "The K-pop hitmakers reflect on their massive international success with Zane.",
    artist_genres: ["K-Pop"],
    artist_url: "https://music.apple.com/us/artist/bts/883131348",
  },
  {
    artist_id: "1208839599",
    artist_name: "NCT DREAM",
    artist_image:
      "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages112/v4/e6/94/e7/e694e7a2-a904-5ab2-537b-ba0ac5fabad5/23835f47-789c-4b4d-817c-2e0898e6c45b_ami-identity-4aa15572d79c4e920f9ca25e883000b4-2024-03-19T09-16-15.094Z_cropped.png/{w}x{h}bb.jpg",
    artist_about: "",
    artist_genres: ["K-Pop"],
    artist_url: "https://music.apple.com/us/artist/nct-dream/1208839599",
  },
];

const pickRandomArtworkFromHomepageArtists = () => {
  let randomIndex;
  do {
    randomIndex = Math.floor(Math.random() * hompageArtists.length);
  } while (usedIndices.has(randomIndex));

  usedIndices.add(randomIndex);
  return hompageArtists[randomIndex].artwork;
};

const pickRandomNumber = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

// create random artwork array of 6 elements using pickRandomArtworkFromHomepageArtists function
const images1 = Array.from({ length: 6 }, pickRandomArtworkFromHomepageArtists);
const images2 = Array.from({ length: 6 }, pickRandomArtworkFromHomepageArtists);
const images3 = Array.from({ length: 6 }, pickRandomArtworkFromHomepageArtists);
const images4 = Array.from({ length: 6 }, pickRandomArtworkFromHomepageArtists);
const images5 = Array.from({ length: 6 }, pickRandomArtworkFromHomepageArtists);

const HomeScreen = () => {
  const [count, setCount] = useState(0);
  const [artistImage, setArtistImage] = useState("");
  const [artistFromApi, setArtistFromApi] = useState("");
  const [artistName, setArtistName] = useState("");
  const [loader, setLoader] = useState(false);
  const [artistId, setArtistId] = useState("");
  const [buttonEnabled, setButtonEnabled] = useState(true);
  const [artists, setArtists] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const debouncedArtistName = useDebounce(artistName, 1000);

  const socials = [
    {
      name: "Instagram",
      link: "https://www.instagram.com/muzifyhq/",
      icon: <FaInstagram fontSize={'1.5em'} color="white" />
    },
    {
      name: "Twitter",
      link: "https://x.com/MuzifyHQ",
      icon: <FaTwitter fontSize={'1.5em'} color="white"/>
    },
    {
      name: "TikTok",
      link: "https://www.tiktok.com/@muzifyhq",
      icon: <FaTiktok fontSize={'1.5em'} color="white"/>
    }
  ]

  useEffect(() => {
    if (localStorage.getItem('loggedIn') === 'true') {
      console.log('logged in');
      clevertap.setLogLevel(3);
      clevertap.init(CLEVERTAP_ACCOUNT_ID);
    }
  }, []);

  const navigate = useNavigate();
  const posthog = usePostHog();

  const { handleEventPushClick } = useClevertap();
  
  const handleClick = () => {
    posthog?.capture("artist_selected", {
      artist_name: artistName,
      artist_id: artistId,
    });

    handleEventPushClick("MUSICNERD_ARTIST_SELECTED", {
      artist_name: artistName,
      artist_id: artistId,
      click: "Let's go",
    });
    postArtistEvent(artistId, artistName, artistImage, INCREMENT_ARTIST_GAMEPLAY);
    navigate(`/quiz/${artistId}`, {
      state: {
        artistName: artistName,
        artistImage: artistImage,
      },
    });
  };

  const handleTopArtistClick = (artist: any, index: any) => {
    posthog?.capture("artist_selected", {
      artist_name: artist.artist_name,
      artist_id: artist.artist_id,
    });
    handleEventPushClick("MUSICNERD_ARTIST_SELECTED", {
      artist_name: artist.artist_name,
      artist_id: artist.artist_id,
      click: "Top Artist",
    });
    ReactGA.event({
      category: "Top Artist",
      action: "click",
      label: `MUSICNERD_TOP_ARTIST_BUTTON_${index + 1}`,
    });
    postArtistEvent(artist.artist_id, artist.artist_name, artist.artist_image, INCREMENT_ARTIST_GAMEPLAY);
    navigate(`/quiz/${artist.artist_id}`, {
      state: {
        artistName: artist.artist_name,
        artistImage: artist.artist_image,
      },
    });
  };

  const formatNumber = (num: number) => {
    // if (Math.abs(num) > 999) {
    //   return (
    //     Math.sign(num) * parseFloat((Math.abs(num) / 1000).toFixed(1)) + "k"
    //   );
    // } else {
    //   return Math.sign(num) * Math.abs(num);
    // }
    // add commas to number
    return num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const getTotalGameCount = useCallback(() => {
    fetch(
      `${process.env.REACT_APP_ASSET_API}/api/v1/music/musicnerd/totalgames`
    )
      .then((response) => response.json())
      .then((data) => {
        setCount(data?.data);
      });
  }, []);

  useEffect(() => {
    getTotalGameCount();
  }, [getTotalGameCount]);

  async function indexArtist(artist_id: number | string) {
    try {
      if (artist_id) {
        const url = `${process.env.REACT_APP_ASSET_API}/music/artist/index/applemusic/${artist_id}`;
        // run async request for indexing
        fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  const handleDropdownItemClick = (
    id: string,
    artistImage: string,
    artistName: string
  ) => {
    posthog?.capture("artist_selected", {
      artist_name: artistName,
      artist_id: id,
    });
    setButtonEnabled(false);
    setArtistId(id);
    setArtistImage(artistImage);
    setArtistName(artistName);
    setArtistFromApi(artistName);
    setDropdownVisible(false);
  };

  const getArtistData = async (artistName: string) => {
    posthog?.capture("artist_searched", { artist_name: artistName });
    try {
      setLoader(true);
      setButtonEnabled(false);
      const response = await searchArtist(artistName);
      if (response.data.length !== 0) {
        let artistId = response.data.data[0].artist_id;
        let artistImage = response.data.data[0].artist_image;
        let artistName = response.data.data[0].artist_name;
        setArtistId(artistId);
        setArtistImage(artistImage);
        setArtistFromApi(artistName);
        setArtists(response.data.data.slice(0, 5));
        postArtistEvent(artistId, artistName, artistImage, INCREMENT_ARTIST_SEARCH);
        setDropdownVisible(false);
        if (response?.data?.data[0]?.artist_id) {
          indexArtist(response?.data?.data[0]?.artist_id);
        }
      }
    } catch (e) {
      setButtonEnabled(true);
      setLoader(false);
      toast.error("The artist you entered does not exist", {
        position: toast.POSITION.TOP_CENTER,
      });
      console.log("error");
    }
    setLoader(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        // console.log("first", debouncedArtistName);
        const response = await searchArtist(debouncedArtistName);
        console.log("response", response);
        if (response && response.data && response.data.length !== 0) {
          setArtists(response.data.data.slice(0, 5));
          let artistId = response.data.data[0].artist_id;
          let artistImage = response.data.data[0].artist_image;
          let artistName = response.data.data[0].artist_name;

          postArtistEvent(artistId, artistName, artistImage, INCREMENT_ARTIST_SEARCH);
        }
        setLoading(false);
      } catch (e) {
        console.error("Error fetching data:", e);
        setLoading(false);
      }
    };
    if (debouncedArtistName) {
      fetchData();
    }
  }, [debouncedArtistName]);

  return (
    <div className="overflow-x-hidden h-screen">
      {/* Banner */}
      <Banner />
      {/* <Header /> */}
      <div className="bg-blue flex md:flex-row flex-col w-screen h-screen overflow-y-scroll md:overflow-y-hidden justify-between items-center overflow-hidden">
        <div className="md:block hidden">
          <div className="flex flex-row items-center justify-center grow-0 gap-5 md:pl-10">
            <div className="flex flex-col gap-8">
              {images1.map((image, index) => (
                <img
                  src={image}
                  alt={`image-${index}`}
                  style={{ opacity: "0.6" }}
                  className="w-40 h-52 image-container-right rounded-3xl object-cover"
                />
              ))}
            </div>

            <div className="flex flex-col gap-8">
              {images2.map((image, index) => (
                <img
                  src={image}
                  alt={`image-${index}`}
                  style={{ opacity: "0.6" }}
                  className="w-40 h-52 image-container-left rounded-3xl object-cover"
                />
              ))}
            </div>
          </div>
        </div>

        <div
          className={`flex flex-col h-screen items-center justify-between grow gap-4 ${artistImage !== "" ? "py-10" : "py-20"
            }`}
        >
          <div className="flex flex-col items-center gap-2 justify-center">
          <Link className="flex flex-row" to="/">
            <MusicNerd />
            {/* <span className="text-2xl text-center font-extrabold text-[#FFFFFF] cabinet-grotesk">
              MUSIC NERD
            </span> */}
          </Link>
          <div className="flex flex-row items-center gap-2 justify-center">
            <p className="text-[17px] text-center text-[#FFFFFF] cabinet-grotesk">
              by
            </p>
            <Link className="flex flex-row gap-2" to="https://muzify.com/" target="_blank">
              <MuzifyLogo />
            </Link>
          </div>
          </div>
          
          <div className="w-full flex flex-col items-center gap-4">
            <div className="flex flex-col items-center">
              <li className="mb-4 pulse-li text-md cabinet-grotesk font-normal">
                <i className="pulse green"></i>
                <span className="text-white">
                  {formatNumber(count)} quizzes played
                </span>
              </li>
              <span className="md:text-5xl text-4xl text-center font-extrabold text-[rgba(239,209,101,1)] cabinet-grotesk">
                Are you a true
              </span>
              <div className="flex">
                <span className="md:text-5xl text-4xl text-center font-extrabold text-[rgba(239,209,101,1)] cabinet-grotesk">
                  music fan?
                </span>
                {/* <span className="md:text-5xl text-3xl  text-center font-extrabold text-[white] ml-2 cabinet-grotesk">
                Guess
              </span> */}
              </div>
              {/* <span className="md:text-5xl text-3xl font-extrabold text-[white] cabinet-grotesk">
              the Tracks!
            </span> */}
            </div>
            <span className="font-normal cabinet-grotesk text-lg text-[rgba(255,255,255,1)] md:w-[63%] md:px-0 px-3 w-full text-center">
              Guess the track within 5 seconds
            </span>

            {/* <button
              className="bg-[rgba(239,209,101,1)] text-black font-extrabold cabinet-grotesk w-80 h-12 md:w-64 md:h-16 rounded-full"
              onClick={handleClick}
            >
              Start Now
            </button> */}
            <div className="flex flex-col gap-6 justify-center items-center">
              {artistImage !== "" ? (
                <div className="flex flex-col justify-center items-center gap-2 ">
                  <img
                    src={replaceDimensionsInUrl(artistImage)}
                    alt="artist"
                    className="w-20 h-20 rounded-full object-cover"
                  />
                  <span className="text-base text-[rgba(255,255,255,1)] cabinet-grotesk text-center">
                    {artistFromApi}
                  </span>
                </div>
              ) : (
                ""
              )}

              <div className="flex flex-grow focus-within:z-10 items-stretch relative">
                <IoIosSearch
                  color="white"
                  size={25}
                  className="absolute left-4 top-1/2 transform -translate-y-1/2"
                />
                <input
                  className="block md:h-16 md:w-80 w-66 h-14 text-white rounded-full border border-transparent bg-[rgba(28,33,36,1)] pl-14   focus:outline-none text-base"
                  placeholder="Type an artist's name"
                  type="text"
                  value={artistName}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      getArtistData(artistName);
                    }
                  }}
                  onChange={(event) => {
                    setLoading(true);
                    setDropdownVisible(true);
                    setArtistName(event.target.value);
                  }}
                />
              </div>
              {loading && artistName !== "" && artistFromApi === "" ? (
                <div className="absolute md:mt-[22%] mt-[80%] md:w-96 w-80 h-80 bg-[rgba(28,33,36,1)] text-center rounded-xl shadow-lg z-10">
                  <div className="mt-28">
                    <svg
                      aria-hidden="true"
                      className="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-black"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                  </div>
                </div>
              ) : dropdownVisible && !loading ? (
                <div className="absolute md:mt-[22%] mt-[80%] md:w-96 w-80 h-80 bg-[rgba(28,33,36,1)] rounded-xl shadow-lg z-10">
                  {artists.map((artist: any) => (
                    <div
                      key={artist?.artist_id}
                      className="p-2 hover:bg-[#272d31] cursor-pointer flex item-start gap-2 justify-start"
                      onClick={() =>
                        handleDropdownItemClick(
                          artist.artist_id,
                          artist.artist_image,
                          artist.artist_name
                        )
                      }
                    >
                      <img
                        src={
                          artist?.artist_image !== ""
                            ? replaceDimensionsInUrl(artist?.artist_image)
                            : DummyImage
                        }
                        alt={artist}
                        className="w-12 h-12 rounded-xl object-cover mr-2"
                      />
                      <p className="text-base text-white pt-3">
                        {artist?.artist_name}
                      </p>
                    </div>
                  ))}
                </div>
              ) : null}
              <button
                className={`bg-[rgba(239,209,101,1)] cabinet-grotesk md:w-96 w-72 h-14 rounded-full ${buttonEnabled ? "disabled:opacity-25" : ""
                  }`}
                disabled={buttonEnabled}
                onClick={handleClick}
              >
                {loader ? (
                  <svg
                    aria-hidden="true"
                    className="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-black"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                ) : (
                  <p className="text-base text-[rgba(20,26,30,1)] font-cabinet-grotesk font-bold">
                    {artistName !== "" ? "I'm ready!" : "Let's go"}
                  </p>
                )}
              </button>
            </div>

            {/* top MN songs */}
            <div className="flex flex-row items-center gap-4 mt-4">
              {topArtistsonMN.map((artist, index) => (
                <div
                  onClick={() => handleTopArtistClick(artist, index)}
                  key={index}
                  className="flex flex-col items-center gap-2 cursor-pointer"
                >
                  <img
                    src={replaceDimensionsInUrl(artist.artist_image)}
                    alt={artist.artist_name}
                    className="w-10 h-10 rounded-full object-cover"
                  />
                  <span className="text-sm text-white text-center font-normal cabinet-grotesk">
                    {artist.artist_name}
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div className="md:hidden">
            <div className="flex flex-row gap-3 mt-3">
              {images5.map((image, index) => (
                <img
                  src={image}
                  alt={`image-${index}`}
                  style={{ opacity: "0.6" }}
                  className="w-28 h-36 rounded-3xl slide-left object-cover"
                />
              ))}
            </div>
          </div>
          <div className="flex flex-row mt-6 items-center justify-center ">
            {/* <span className="text-sm text-center text-[rgba(182,182,182,1)] md:w-[63%] md:px-0 px-3  w-full font-normal">
              From the House of{" "}
              <a
                href="https://www.asset.money/"
                target="_blank"
                rel="noreferrer"
                className="font-bold text-[rgba(239,209,101,1)]"
              >
                <img src={Assetlogo} alt="asset" className="w-12 inline" />
                Asset.Money
              </a>
            </span> */}
            <a
              className="text-xs text-center text-[rgba(255,255,255,0.4)] px-3 pt-1 underline font-normal"
              href="https://teamasset.notion.site/MusicNerd-FAQs-To-be-published-8f2020a3f84e4c42b35b8eef6dae4320"
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                posthog.capture("faqs_clicked");
              }}
            >
              FAQs
            </a>
            <a
              className="text-xs text-center text-[rgba(255,255,255,0.4)] px-3 pt-1 underline font-normal"
              href="https://tally.so/r/mKL17D"
              target="_blank"
              rel="noreferrer"
            >
              Partner with us
            </a>
          </div>
          <div className="flex flex-row pt-2 pb-8 items-center justify-center ">
            {socials.map((social) => (
              <a
                className="px-2"
                href={social.link}
                target="_blank"
                rel="noreferrer"
                key={social.name}
              >
                {social.icon}
              </a>
            ))}
          </div>
        </div>

        <div className="md:block hidden">
          <div className="flex flex-row items-center justify-center grow-0 gap-5 md:pr-10">
            <div className="flex flex-col gap-8">
              {images3.map((image, index) => (
                <img
                  src={image}
                  alt={`image-${index}`}
                  style={{ opacity: "0.6" }}
                  className="w-40 h-52 rounded-3xl object-cover image-container-right"
                />
              ))}
            </div>
            <div className="flex flex-col gap-8">
              {images4.map((image, index) => (
                <img
                  src={image}
                  alt={`image-${index}`}
                  style={{ opacity: "0.6" }}
                  className="w-40 h-52 rounded-3xl object-cover image-container-left"
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <a className="text-[#141a1e] text-sm" href="https://muzify.com/quiz">
          Muzify
      </a>
      <ToastContainer />
    </div>
  );
};

export default HomeScreen;
