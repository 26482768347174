import { useState, useEffect } from "react";
import { usePostHog } from "posthog-js/react";
import { getGlobalLeaderboard } from "../endpoints/api";
import { useParams, useLocation, Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/AssetLogo.svg"; // Adjust path as needed
import useAsset from "../hooks/useAsset";
import MuzifyLogo from "../assets/images/logo.png";
import { AvatarGenerator } from "random-avatar-generator";
import { artists } from "../constants/artistsData";
import Header from "../components/Header";

const statuses: Record<string, string> = {
  Completed: "text-green-400 bg-green-400/10",
  Error: "text-rose-400 bg-rose-400/10",
};

type User = {
  uuid: string;
  username: string;
  profile_image: string;
  score: number;
  time: number;
  logged: boolean
};


type ArtistGlobalData = {
  artist_name: string;
  artist_id: string;
  artist_image: string;
  quizes_played: number;
  score: number;
  time_taken: number;
  username: string;
};


type Result = {
  data: ArtistGlobalData[];
};


interface UserInfo {
  username: string;
  bio: string;
  coverpic: string;
  created: number;
  email: string;
  firstname: string;
  followers: null;
  following: null;
  genre: [];
  lastname: string;
  mobile: number;
  profilepic: string;
  spotifytoken: string;
  status: string;
  telegramtoken: string;
  tokens: { applemusic: boolean; spotify: boolean; gmail: boolean };
  updated: number;
  uuid: string;
  wallet: string;
}

// const activityItems = [
//   {
//     user: {
//       name: 'Michael Foster',
//       imageUrl:
//         'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
//     },
//     right: 2,
//     wrong: 3,
//     score: 2,
//   },

// ]

const clearUsername = (username: string) => {
  // remove numbers from username
  username = username.replace(/[0-9]/g, "");
  // remove special characters from username
  username = username.replace(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g, "");
  // check for uppercase letter in username - exculde the first letter
  let dupUsername = username.slice(1, username.length);
  // get first uppercase letter
  let uppercaseLetter = dupUsername.match(/[A-Z]/);
  if (uppercaseLetter) {
    // get the index of the first uppercase letter
    let index = dupUsername.indexOf(uppercaseLetter[0]);
    // if index is greater than 0 then slice the username from 0 to index
    if (index > 0) {
      return username.slice(0, index + 1);
    } else {
      return username;
    }
  } else {
    return username;
  }
};

export default function ArtistLeaderboard() {
  const [isOpen, setIsOpen] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [data, setData] = useState<ArtistGlobalData[] | null>(null);
  const { id, playername } = useParams();
  const location = useLocation();
  const [quizData, setQuizData] = useState([]);
  const [randUsername, setRandUsername] = useState<string | null>("");
  const score = location?.state?.score;
  const totalScore = location?.state?.totalScore;
  const posthog = usePostHog();

  const generator = new AvatarGenerator();

  const AppURL = process.env.REACT_APP_URL;
  const {
    accessToken,
    setAccessToken,
    userData,
    getUser,
    username,
    userInfo: untypedUserInfo,
  } = useAsset();
  const userInfo = untypedUserInfo as unknown as UserInfo;

  const displayTime = (time: number) => {
    if (time < 60) {
      return `${time}s`;
    } else if (time >= 60 && time < 3600) {
      return `${Math.floor(time / 60)}m ${time % 60}s`;
    } else {
      return `${Math.floor(time / 3600)}h ${Math.floor((time % 3600) / 60)}m ${time % 60
        }s`;
    }
  };

  const handleShare = async () => {
    if (navigator.share) {
      // Web Share API for mobile devices
      await navigator.share({
        title: document.title,
        text: "I just discovered a fun website to watch wonderful YT videos - ",
        url: window.location.href,
      });
    } else {
      setIsOpen(true);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  // console.log("score", score);
  // console.log("toal score", totalScore);


  const getScores = async () => {
    const response = await getGlobalLeaderboard();
    // console.log(response.data.data)
    setData(response.data.data);
  };

  function replaceDimensionsInUrl(url: string) {
    const lastSlashIndex = url?.lastIndexOf("/");
    if (
      lastSlashIndex !== -1 &&
      (url?.includes("{w}x{h}bb.jpg") || url?.includes("{w}x{h}ac.jpg"))
    ) {
      const baseUrl = url?.substring(0, lastSlashIndex);
      const newUrl = `${baseUrl}/200x200bb.jpg`;
      return newUrl;
    }
    return url;
  }


  useEffect(() => {
    // Fetch the data when the component mounts
    getScores();
  }, []);

  // useEffect(() => {
  //   if (accessToken) {
  //     getUser();
  //   }
  // }, [accessToken]);

  return (
    <div className="flex flex-col">
      <div className="fixed flex flex-col w-full z-50">
        {/* <Header /> */}
      </div>
      <div className="bg-blue h-screen w-screen">
        <Link to="/" className="flex justify-center flex-row gap-2 pt-20">
          <img src={MuzifyLogo} alt="muzify" className="w-32" />
        </Link>
        <div className="flex flex-col md:flex-row mt-4 justify-center items-center">
          <p className="ml-4 mt-5 text-center font-extrabold text-2xl text-yellow">
            Global Artist Leaderboard
          </p>
        </div>

        <div className="mt-10 mb-4 flex justify-center flex-col md:flex-row">        
       
          <div className="max-h-[680px] mb-5 mt-5 rounded-xl overflow-y-auto">
            <table className="md:w-min-[340px] whitespace-nowrap text-left relative">
              <colgroup>
                <col className="w-4/12" />
                <col className="w-4/12" />
                <col className="w-4/12" />
              </colgroup>
              <thead className="text-gray h-14 text-sm leading-6 text-white sticky bg-gray top-0 z-10">
                <tr>
                  <th scope="col" className="py-2 text-center md:text-start pl-4 pr-8 font-semibold sm:pl-6 lg:pl-8">
                    Artist
                  </th>
                  <th scope="col" className="py-2 pl-0 pr-4 font-semibold sm:pr-8 text-center lg:pr-20">
                    Fans Played
                  </th>
                  <th scope="col" className="py-2 pl-0 pr-4 font-semibold sm:pr-8 text-center lg:pr-20">
                    Score
                  </th>
                </tr>
              </thead>
              <tbody className="bg-gray">
                {data?.map((artist: ArtistGlobalData, index: number) => (
                  <tr className={`${index === 0 ? "rounded-tl-md rounded-tr-md bg-yellow text-black" : "text-white"
                    } ${index === artists.length - 1 ? "rounded-bl-md text-white rounded-br-md" : ""}`}>
                    <td className="flex flex-row py-4 pl-8 pr-2 md:pr-8 md:pl-8 h-14">
                      <p className="mr-6">{index + 1}</p>
                      <div className="flex items-center gap-x-2">
                        <img
                          src={artist?.artist_image?.length > 0 ? replaceDimensionsInUrl(artist?.artist_image) : ""}
                          alt=""
                          className="h-8 w-8 rounded-full bg-gray-800"
                        />
                        <div className="truncate text-sm font-medium leading-6 md:w-52">
                          {artist?.artist_name?.length > 0 ? artist?.artist_name : ""}
                        </div>
                      </div>
                    </td>
                    <td className="py-4 pl-0 pr-2 sm:pr-8 lg:pr-20">
                      <div className="flex items-center gap-x-2 justify-center">
                        <div className="sm:block">{artist?.quizes_played}</div>
                      </div>
                    </td>
                    <td className="py-4 pl-0 pr-2 sm:pr-8 lg:pr-20">
                      <div className="flex items-center gap-x-2 justify-center">
                        <div className="sm:block">{artist?.score} ({displayTime(artist?.time_taken)})</div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
  
        </div>
      </div>
      <a className="text-[#141a1e] text-sm" href="https://muzify.com/quiz">
          Muzify
      </a>
    </div>

  );
}
