import React, { useState } from "react";
import { Link } from "react-router-dom";
import MuzifyLogo from "../assets/images/logo.png";
import { usePostHog } from "posthog-js/react";
import ReactPaginate from "react-paginate";
import useWindowSize from "../hooks/useWindowSize";

import { artists } from "../constants/artistsData";

const Artists = () => {
  const posthog = usePostHog();

  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const windowSize = useWindowSize();
  const [itemOffset, setItemOffset] = useState(0);
  // itemsPerPage depend on screen size
  const [itemsPerPage, setItemsPerPage] = useState(
    windowSize > 1200 ? 18 : windowSize > 1024 ? 14 : windowSize > 768 ? 15 : windowSize > 640 ? 9 : 6
  );

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = itemOffset + itemsPerPage;
  // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = artists.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(artists.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % artists.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  return (
    <div className="bg-blue flex flex-col justify-center items-center gap-4">
      <div className="flex flex-row gap-2 mt-4">
        <img src={MuzifyLogo} alt="muzify" className="w-8" />
      </div>
      <div>
        <div className="flex flex-col justify-center items-center gap-7">
          <h1 className="text-[rgba(239,209,101,1)] sm:text-2xl lg:text-3xl font-bold">
            Top Global Artists
          </h1>
          <div className="flex flex-col justify-center items-center sm:px-32">
            {/* loop over artists and display artists.artwork(image) with name below and make sure the grid/flebox adjusts with the screen size and paginate */}
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-8 gap-8">
              {currentItems.map((artist) => (
                <div key={artist.id} className="flex flex-col items-center">
                  <Link
                    to={`/quiz/${artist.id}`}
                    onClick={() => {
                      posthog.capture("artist_clicked", {
                        artist: artist.name,
                      });
                    }}
                  >
                    <img
                      src={artist.artwork}
                      alt={artist.name}
                      className="w-28 h-36 rounded-xl object-cover"
                    />
                  </Link>
                  <p className="text-[rgba(239,209,101,1)] text-center text-xs sm:text-sm lg:text-base">
                    {artist.name}
                  </p>
                </div>
              ))}
            </div>
            <ReactPaginate
              nextLabel=""
              previousLabel=""
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              pageCount={20}
              pageRangeDisplayed={4}
              marginPagesDisplayed={2}
              onPageChange={handlePageClick}
              containerClassName="pagination justify-content-center"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              activeClassName="active"
              // eslint-disable-next-line no-unused-vars
              hrefBuilder={(page, pageCount, selected) =>
                page >= 1 && page <= pageCount ? `/page/${page}` : "#"
              }
              hrefAllControls
              forcePage={0}
              onClick={(clickEvent) => {
                console.log("onClick", clickEvent);
                // Return false to prevent standard page change,
                // return false; // --> Will do nothing.
                // return a number to choose the next page,
                // return 4; --> Will go to page 5 (index 4)
                // return nothing (undefined) to let standard behavior take place.
              }}
            />
          </div>
        </div>
      </div>
      <a className="text-[#141a1e] text-sm" href="https://muzify.com/quiz">
          Muzify
      </a>
    </div>
  );
};

export default Artists;
