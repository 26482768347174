import React, { useState, useEffect } from "react";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  OAuthProvider,
  User,
} from "firebase/auth";
import clevertap from "clevertap-web-sdk";
import { CLEVERTAP_ACCOUNT_ID } from "../constants/dataConstant";
import { useNavigate } from "react-router-dom";
import useAsset from "../hooks/useAsset";
import { postUserLocation, postUserWallet } from "../endpoints/api";
import axios from "axios";
import { generateUsername } from "friendly-username-generator";

const Signup = () => {
  const navigate = useNavigate();
  const {
    getUser,
    accessToken,
    setAccessToken,
    user,
    setUser,
    userInfo,
    createUser,
    claimNft,
  } = useAsset();

  const [userCountry, setUserCountry] = useState("");
  const [userRegion, setUserRegion] = useState("");
  const [locationDataReady, setLocationDataReady] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchLocationData = async () => {
      const cachedData = localStorage.getItem("locationData");

      if (cachedData) {
        // Use cached data if available
        const { country, region } = JSON.parse(cachedData);
        setUserCountry(country);
        setUserRegion(region);
        setLocationDataReady(true);
      } else {
        // If no cached data, make an API call
        try {
          const response = await axios.get(
            `https://ipapi.co/json/?key=${process.env.REACT_APP_IP_API_KEY}`
          );
          const { country_name, region } = response.data;
          setUserCountry(country_name);
          setUserRegion(region);
          setLocationDataReady(true);

          // Cache the new data
          localStorage.setItem(
            "locationData",
            JSON.stringify({ country: country_name, region })
          );
        } catch (error) {
          console.error("Error fetching location data:", error);
          setUserCountry("Unknown");
          setUserRegion("Unknown");
          setLocationDataReady(false);
        }
      }
    };
    fetchLocationData();
  }, []);

  const handlePostUserLocation = (username, uuid) => {
    if (locationDataReady) {
      postUserLocation(username, uuid, userCountry, userRegion);
    }
  };

  const sleep = async (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const createWalletForUser = async (username) => {
    try {
      let walletExists = localStorage.getItem("wallet");
      if (walletExists) {
        return;
      }
      const body = {
        username: username,
      };
      console.log("1 BODY WALLET", body);
      let response = await postUserWallet(body);
      console.log("response WALLET", response);
      let data = response.data;
      console.log("data WALLET", data);
      if (data?.wallet) {
        localStorage.setItem("wallet", data.wallet);
      }
      return data;
    } catch (e) {
      console.log("error", e);
    }
  };

  const handleGoogleSignIn = async () => {
    setLoading(true);
    clevertap.setLogLevel(3);
    clevertap.init(CLEVERTAP_ACCOUNT_ID);
    const auth = getAuth();
    const provider = new GoogleAuthProvider();

    try {
      const result = await signInWithPopup(auth, provider);
      setUser(result.user);
      clevertap.profile.push({
        Site: {
          Name: result.user?.displayName,
          Email: result.user?.email,
        },
      });
      console.log(result.user);

      if (result.user) {
        try {
          const old_username = localStorage.getItem("username");
          handlePostUserLocation(old_username, result.user.uid);

          let savedUsername = localStorage.getItem("username");

          if (typeof savedUsername !== "string" || savedUsername.length === 0) {
            savedUsername = generateUsername(); // Generate a username of 10 characters
            localStorage.setItem("username", savedUsername);

            createWalletForUser(savedUsername);
          }
          const body = {
            old_username:
              old_username?.length > 0 ? old_username : savedUsername,
            username: result.user.displayName,
          };

          let checkIfReferralUsed = localStorage.getItem("referralCodeUsed");
          if (checkIfReferralUsed === null) {
            let ref = localStorage.getItem("ref");
            console.log("Referral code", ref);
            const body = {
              referralCode: ref,
              referreduser: result.user.email,
            };
            fetch(`https://cruncher.asset.money/misc/referral`, {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(body),
            })
              .then((response) => {
                console.log(response);
                localStorage.setItem("referralCodeUsed", "true");
              })
              .catch((err) => {
                console.log(err?.message);
              });
          }

          result.user.getIdToken().then(async (token) => {
            setAccessToken(token);
            localStorage.setItem("loggedIn", "true");

            await sleep(5000);
            navigate(`/quiz`);
            setAccessToken(token);
          });
        } catch (e) {
          console.log("error", e);
        }
      }
    } catch (error) {
      console.error("Google Sign-In Error:", error);
    } finally {
      //   setSaveLoading(false);
      setLoading(false);
    }
  };

  return (
    <div className=" h-screen">
      <div className="bg-blue flex flex-col w-screen h-screen justify-center items-center gap-10">
        <h1 className="text-2xl font-bold text-[rgba(239,209,101,1)]">
          Signup to play the quiz
        </h1>
        {loading ? (
          <div className="flex flex-col w-screen justify-center items-center gap-5 mb-12">
            <div className="font-bold text-[rgba(239,209,101,1)] text-xl">Loading...</div>
          </div>
        ) : (
          <div className="flex flex-col w-screen justify-center items-center gap-5 mb-12">
            <button
              className="bg-[rgba(239,209,101,1)] text-black inter w-80 md:w-72 h-12 rounded-full"
              onClick={handleGoogleSignIn}
              disabled={loading}
            >
              <p className="cabinet-grotesk text-base font-bold">
                Signup with Google
              </p>
            </button>
          </div>
        )}
      </div>
      <a className="text-[#141a1e] text-sm" href="https://muzify.com/quiz">
          Muzify
      </a>
    </div>
  );
};

export default Signup;
